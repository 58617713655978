import type { MouseEvent } from 'react'

import type { ChakraProps, MenuProps } from '@chakra-ui/react'
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'

import {
  IoBookOutline,
  IoDocumentTextOutline,
  IoHelpCircleOutline,
  IoPerson,
  IoPower,
} from 'src/components/Icons'

import { ColorModeSwitch } from '../ColorModeSwitch/ColorModeSwitch'

const menuIconStyle: ChakraProps = {
  boxSize: 6,
  mr: 4,
}

interface ProfileProps extends Omit<MenuProps, 'children'> {
  userDisplayName: string
  logoutText: string
  faqText?: string
  manualText?: string
  releaseNotesText?: string
  title?: string
  showColorModeToggle?: boolean
  isSmall?: boolean
  onLogoutClick: (event: MouseEvent<HTMLButtonElement>) => Promise<unknown>
}

const Profile = ({
  userDisplayName,
  logoutText = 'Logout',
  faqText = 'FAQ',
  manualText = 'Manual',
  releaseNotesText = 'Release notes',
  showColorModeToggle = false,
  isSmall = false,
  ...props
}: ProfileProps) => {
  const menuLinks = [
    { url: process.env.FAQ_URL, text: faqText, icon: IoHelpCircleOutline },
    { url: process.env.MANUAL_URL, text: manualText, icon: IoBookOutline },
    {
      url: process.env.RELEASE_NOTES_URL,
      text: releaseNotesText,
      icon: IoDocumentTextOutline,
    },
  ]

  return (
    <Menu {...props} variant="ivt-profile" isLazy>
      <MenuButton
        title={props.title ?? userDisplayName}
        aria-label="User"
        as={Button}
        variant="ivt-outline-rounded"
        size="custom"
        {...(isSmall
          ? { height: 9, width: '3.75rem' }
          : { height: [9, '2.625rem'], width: ['3.75rem', 20] })}
      >
        <Icon boxSize="6" as={IoPerson} verticalAlign="middle" />
      </MenuButton>
      <MenuList zIndex="popover">
        <MenuGroup title={userDisplayName}>
          {showColorModeToggle && (
            <MenuItem as={ColorModeSwitch} iconProps={menuIconStyle} />
          )}
          {menuLinks.map(
            ({ url, text, icon }) =>
              url && (
                <MenuItem
                  as="a"
                  href={url}
                  target="_blank"
                  key={text}
                  closeOnSelect={false}
                >
                  <Icon as={icon} {...menuIconStyle} />
                  {text}
                </MenuItem>
              )
          )}
          <MenuItem onClick={props.onLogoutClick}>
            <Icon as={IoPower} {...menuIconStyle} />
            {logoutText}
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export default Profile
