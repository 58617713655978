import type {
  FlatNamespace,
  KeyPrefix,
  Namespace,
  TFunction,
  i18n,
} from 'i18next'
import type { FallbackNs } from 'react-i18next'
import { useTranslation } from 'react-i18next'

type $Tuple<T> = readonly [T?, ...T[]]

export default function useI18n<
  Ns extends FlatNamespace | $Tuple<FlatNamespace> | undefined = undefined,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(
  keyPrefix: KPrefix = null,
  namespace: Namespace<string> = 'translation'
): {
  t: TFunction<Ns, KPrefix>
  i18n: i18n
  ready: boolean
} {
  return useTranslation(namespace, keyPrefix ? { keyPrefix } : undefined)
}

export const useI18nApi = (
  keyPrefix: Parameters<typeof useI18n>[0] = null
): ReturnType<typeof useI18n> => useI18n(keyPrefix, 'api')
