import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  useColorModeValue,
} from '@chakra-ui/react'

import { useConfirmDialogStore } from 'src/utils/hooks/useConfirm'

export default function GlobalConfirmDialog() {
  return <ConfirmDialog {...useConfirmDialogStore()} />
}

export interface ConfirmDialogProps {
  isOpen: boolean
  /** A callback that sets `isOpen` to false so the dialog may close itself */
  onClose(): void
  headerText: string
  message?: string
  /** Defaults to 'Cancel' */
  cancelButtonText?: string
  /** Defaults to 'OK' */
  confirmButtonText?: string
  /** A callback that will be executed when confirm-button is clicked */
  onConfirm?(): void
  /** A callback that will be executed when cancel-button is clicked */
  onCancel?(): void
  /** Defaults to false (=cancel button is focussed) */
  isOkButtonFocussed?: boolean
}

export const ConfirmDialog = ({
  isOpen,
  onClose,
  headerText,
  message,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'OK',
  onConfirm,
  onCancel,
  isOkButtonFocussed = false,
}: ConfirmDialogProps) => {
  const focusButtonRef = React.useRef()
  const boxShadow = useColorModeValue(
    // Figma design demands drop-shadow, however that changes with bgColor and is only rendered around fully opaque elements.
    // These boxShadow values are carefully selected equivalents for filter="drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.22))"
    '0px 6px 20px rgba(0 0 0 / 30%)',
    '0px 4px 20px 6px rgb(64 64 64)'
  )

  function handleCancel() {
    onCancel?.()
    onClose()
  }

  function handleConfirm() {
    onConfirm?.()
    onClose()
  }

  return (
    <AlertDialog
      motionPreset="scale"
      leastDestructiveRef={focusButtonRef}
      blockScrollOnMount={false}
      onOverlayClick={onCancel}
      onEsc={onCancel}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay bg="transparent" />

      <AlertDialogContent
        w={['full', '26rem']}
        m={[4, 'auto']}
        boxShadow={boxShadow}
        bgColor={useColorModeValue('smokeyGrey', 'transparent')}
        backdropFilter="blur(20px)"
      >
        <AlertDialogHeader
          minH={10}
          py={3}
          pl={6}
          pr={10}
          fontSize="sm"
          lineHeight="4"
          fontWeight="bold"
          bg={useColorModeValue('whiteAlpha.600', 'smokeyGrey')}
          borderRadius="0.375rem 0.375rem 0 0"
        >
          {headerText}
        </AlertDialogHeader>
        <AlertDialogCloseButton
          size="r-md"
          top={1}
          right={1}
          onClick={onCancel}
        />
        {message?.length > 0 && (
          <AlertDialogBody
            as={Center}
            bgColor="ivtBg"
            py={2.5}
            mb={-4} // make up for top margin of footer
            minH={24}
            lineHeight={5}
            textAlign="center"
            border="1px solid transparent"
          >
            {message}
          </AlertDialogBody>
        )}
        <AlertDialogFooter
          bgColor="ivtBg"
          flexDirection={['column', 'row']}
          rowGap={4}
          justifyContent="space-evenly"
          borderRadius="0 0 0.375rem 0.375rem"
          border="1px solid transparent"
        >
          <Button
            ref={isOkButtonFocussed ? null : focusButtonRef}
            onClick={handleCancel}
            variant="ivt-outline"
            w={['full', 'auto']}
            minW={36}
          >
            {cancelButtonText}
          </Button>
          <Button
            ref={isOkButtonFocussed ? focusButtonRef : null}
            onClick={handleConfirm}
            variant="ivt-solid"
            w={['full', 'auto']}
            minW={36}
          >
            {confirmButtonText}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
