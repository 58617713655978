import type { GlobalStyles } from '@chakra-ui/theme-tools'

const styles: GlobalStyles = {
  // https://v2.chakra-ui.com/docs/styled-system/global-styles
  global: {
    body: {
      bg: 'ivtBg',
      color: 'ivtText',
      /**
       * Fixing footer to the bottom of the page technique with flexbox borrowed from
       * @see https://greggod.medium.com/keep-that-damn-footer-at-the-bottom-959796fe3d08
       */
      '#redwood-app:not(:has(~ #storybook-root)), #storybook-root': {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      },
      main: { mb: 'auto' },
    },
  },
}

export default styles
