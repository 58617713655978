import type { ReactNode } from 'react'

import { ChakraBaseProvider, ColorModeScript } from '@chakra-ui/react'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import ConfirmDialog from 'src/components/ConfirmDialog'
import FatalErrorPage from 'src/pages/FatalErrorPage'

import theme from '../src/theme'
import './index.css'
import './i18n'

import { AuthProvider, useAuth } from './auth'
import { toastConfig } from './utils/config'

interface AppProps {
  children?: ReactNode
}

const App = ({ children }: AppProps) => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider>
        <ColorModeScript />
        <ChakraBaseProvider theme={theme} toastOptions={toastConfig}>
          <ConfirmDialog />
          <RedwoodApolloProvider
            useAuth={useAuth}
            // https://redwoodjs.com/docs/graphql#customizing-the-apollo-client-and-cache
            graphQLClientConfig={{
              cacheConfig: {
                typePolicies: {
                  InterviewByName: {
                    // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
                    keyFields: ['urlName'],
                  },
                },
              },
            }}
          >
            {children}
          </RedwoodApolloProvider>
        </ChakraBaseProvider>
      </AuthProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>
)

export default App
