import { formAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode, toVar } from '@chakra-ui/theme-tools'

import config from '../config'

// somehow chakra system style colors are not resolved in WebkitTextFillColor & CaretColor (possible chakra bug?)
const cssVar = {
  // therefore generating it here
  ivtText: `var(${toVar('colors-ivtText', config.cssVarPrefix)})`,
}

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(formAnatomy.keys)

const disabledStyle = { opacity: 0.4, cursor: 'not-allowed' }
const activeInlineLabelStyle = { display: 'none !important' }

const inlineVariant = definePartsStyle({
  container: {
    input: {
      px: 2,
      cursor: 'pointer',
      _hover: { cursor: 'pointer' },
      _focusWithin: { cursor: 'text' },
      _disabled: disabledStyle,
      _placeholder: { userSelect: 'none' },
      ':not(:focus-within)::placeholder': { color: 'transparent' },
      ':focus-within + label': activeInlineLabelStyle,
      ':not(:placeholder-shown) + label': activeInlineLabelStyle,
    },
    label: {
      color: 'ivtText',
      fontWeight: 'normal',
      marginTop: '.5rem',
      height: 6,
      top: '.5px',
      left: 0,
      zIndex: 2,
      position: 'absolute',
      pointerEvents: 'none',
      mx: 1,
      px: 1,
      whiteSpace: 'nowrap',
    },
    textarea: {
      ':not(:focus-within)::placeholder': { color: 'transparent' },
      ':focus-within + label': activeInlineLabelStyle,
      ':not(:placeholder-shown) + label': activeInlineLabelStyle,
    },
  },
})

const interviewFormVariant = definePartsStyle((props) => {
  const inlineLabelStyle = inlineVariant.container
  const activeStyle = { bg: mode('gray.50', 'whiteAlpha.50')(props) }
  const hoverStyle = {
    bg: mode('lightWhite', 'glass.20')(props),
    cursor: 'pointer',
    _disabled: { bg: 'none' },
  }

  return {
    container: {
      width: 'auto',
      label: {
        ...inlineLabelStyle.label,
        fontSize: 'sm',
        top: '2px',
      },
      input: {
        ...inlineLabelStyle.input,
        h: 9,
        my: 0.5,
        fontSize: 'sm',
        _placeholder: {
          fontSize: 'sm',
          color: mode('blackAlpha.600', 'whiteAlpha.600')(props),
          userSelect: 'none',
        },
        _hover: hoverStyle,
        _focusWithin: { ...activeStyle, cursor: 'text' },
        _autofill: {
          // https://noahliebman.net/2024/08/beating-important-user-agent-styles/
          transition:
            'color calc(infinity * 1s) step-end, background-color calc(infinity * 1s) step-end',
          borderRadius: 0,
          // dirty hack to make chrome behave from https://stackoverflow.com/a/22632017
          WebkitTextFillColor: cssVar.ivtText,
          boxShadow: '0 0 0px 100vw var(--popper-bg) inset',
          // https://stackoverflow.com/a/45491629
          caretColor: cssVar.ivtText,
          _hover: {
            // cannot work with transparency (glass.20) here as vendor style would shine through
            '--autofill-hover': mode('colors.lightWhite', '#575f6d')(props),
            boxShadow: '0 0 0px 100vw var(--autofill-hover) inset',
            borderRadius: 'md',
          },
          _focus: {
            // cannot work with transparency (whiteAlpha.50) here as vendor style would shine through
            '--autofill-focus': mode('colors.gray.50', '#353f4f')(props),
            boxShadow: '0 0 0px 100vw var(--autofill-focus) inset',
            borderRadius: 'md',
          },
        },
      },
      select: {
        h: 9,
        mt: 0.5,
        pl: 2,
        pr: 2,
        minWidth: '80px',
        textAlign: 'center',
        fontSize: 'sm',
        transition: 'background .2s, padding .2s, opacity .1s',
        _hover: {
          ...hoverStyle,
          pr: 6,
          ' + div': { opacity: 0.8, transition: 'opacity 0.4s' },
        },
        _focus: { ...hoverStyle, pr: 6, ' + div': { opacity: 0.8 } },
        _disabled: { ...disabledStyle, pr: 0, ' + div': { opacity: 0 } },
        _active: activeStyle,
        ' + div': {
          opacity: 0,
          right: 0.5,
        },
      },
      textarea: {
        ...inlineLabelStyle.textarea,
        cursor: 'pointer',
        px: 2,
        my: 0.5,
        mb: 2,
        fontSize: 'sm',
        _hover: hoverStyle,
        _focusWithin: { ...activeStyle, cursor: 'text' },
        _disabled: disabledStyle,
        _placeholder: {
          color: mode('blackAlpha.600', 'whiteAlpha.600')(props),
          userSelect: 'none',
        },
        ':not(:placeholder-shown)': {
          width: 'calc(100% + 0.75rem)', // align scrollbar with button
          overflowY: 'scroll',
        },
        '& + label': { top: '.5px' },
      },
    },
  }
})

const activeFloatingLabelStyle = {
  transform: 'scale(0.75) translate(-2px, -8px)',
  opacity: '0.8',
}

const FormStyles = defineMultiStyleConfig({
  variants: {
    // based on https://v2.chakra-ui.com/community/recipes/floating-labels
    floating: {
      container: {
        _focusWithin: {
          label: activeFloatingLabelStyle,
        },
        input: {
          paddingTop: 4,
        },
        'input:not(:placeholder-shown) + label': activeFloatingLabelStyle,
        'input:-webkit-autofill + label': activeFloatingLabelStyle,
        label: {
          color: 'ivtBlue',
          fontWeight: 'normal',
          marginTop: '1rem',
          height: 6,
          top: 0,
          left: 0,
          zIndex: 2,
          position: 'absolute',
          pointerEvents: 'none',
          mx: 4,
          px: 1,
          whiteSpace: 'nowrap',
          transformOrigin: 'left top',
        },
      },
    },
    'ivt-inline': inlineVariant,
    'ivt-interview': (props) => interviewFormVariant(props),
  },
})

export default FormStyles
