import { defineStyleConfig } from '@chakra-ui/react'
import { mode, toVar } from '@chakra-ui/theme-tools'

import config from '../config'

// somehow chakra system style colors are not resolved in WebkitTextFillColor & CaretColor
const cssVar = {
  // therefore generating it here
  ivtBlue: `var(${toVar('colors-ivtBlue', config.cssVarPrefix)})`,
}

const boxSize = {
  width: 72,
  height: 14,
}

const boxBorderReset = {
  borderColor: 'ivtBlue',
  boxShadow: 'none',
}

const InputStyles = defineStyleConfig({
  variants: {
    // for use in conjunction with <FormControl variant="floating" />
    solid: (props) => ({
      field: {
        borderStyle: 'solid',
        borderWidth: mode('1px', '0px')(props),
        bgColor: 'white',
        color: 'ivtBlue',
        ...boxBorderReset,
        _focus: boxBorderReset,
        _invalid: boxBorderReset,
        _hover: boxBorderReset,
        _autofill: {
          // https://noahliebman.net/2024/08/beating-important-user-agent-styles/
          transition:
            'color calc(infinity * 1s) step-end, background-color calc(infinity * 1s) step-end',
          // dirty hack to make chrome behave from https://stackoverflow.com/questions/70809036/
          boxShadow: '0 0 0px 100vw white inset',
          WebkitTextFillColor: cssVar.ivtBlue,
          caretColor: cssVar.ivtBlue,
        },
        ...boxSize,
      },
      addon: boxSize,
    }),
  },
})

export default InputStyles
