import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from '@chakra-ui/react'

const Calendar = createMultiStyleConfigHelpers([
  'calendar',
  'months',
]).defineMultiStyleConfig({
  baseStyle: {
    calendar: {
      borderRadius: 'lg',
      borderWidth: null,
      bgColor: 'white',
    },
    months: {
      p: 3,
    },
  },
})

/* This is just the button area at the top */
const CalendarControl = createMultiStyleConfigHelpers([
  'controls',
  'button',
]).defineMultiStyleConfig({
  baseStyle: {
    controls: {
      p: 3,
      py: 2,
      pb: 0,
      justifyContent: 'flex-end',
    },
    button: {
      bgColor: 'transparent',
      color: 'darken.60',
      h: 8,
      px: 2.5,
      py: 1.5,
      my: 1,
      _hover: {
        bgColor: 'gray.200',
      },
      _active: {
        bgColor: 'gray.300',
      },
    },
  },
})

const CalendarMonth = createMultiStyleConfigHelpers([
  'month',
  'name',
  'week',
  'weekday',
]).defineMultiStyleConfig({
  baseStyle: {
    // The box enclosing the day grid
    month: {
      px: 1,
      pb: 0.5,
    },
    // The current month name in the header
    name: {
      color: 'darken.60',
      fontSize: 'sm',
      fontFamily: null, // reset DatePicker setting (=auto-inherit)
      fontWeight: 'medium',
      lineHeight: 9,
      pr: '74px',
      textAlign: 'center',
    },
    // The box enclosing the day names just below the header
    week: {
      mt: 1,
      py: 0.5,
      borderStyle: null,
    },
    // The day names just below the header
    weekday: {
      fontSize: 'sm',
      color: 'darken.60',
      lineHeight: 'taller',
    },
  },
})

const CalendarDay = defineStyleConfig({
  baseStyle: {
    color: 'darken.87',
    rounded: 'full',
    fontSize: 'xs',
    fontWeight: 'normal',
    px: null,
    minWidth: 7,
    m: 0.5,
    height: 7,
    transitionProperty: 'border',
    transitionDuration: '250ms',
    _disabled: {
      color: 'darken.38',
      cursor: 'default',
    },
    _hover: {
      bgColor: 'gray.200',
      transitionDuration: '250ms',
    },
    _active: {
      bgColor: 'gray.300',
      boxShadow: 'none',
      transitionProperty: 'background',
      transitionDuration: '250ms',
    },
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    outside: {
      color: 'darken.38',
    },
    today: {
      bgColor: 'rose',
    },
    selected: {
      color: 'darken.87',
      border: '1px solid black',
      bgColor: 'transparent !important',
      transitionProperty: 'background',
      transitionDuration: '100ms',
      _hover: {
        bgColor: 'transparent',
        cursor: 'default',
      },
      _active: {
        bgColor: 'gray.300',
      },
    },
  },
})

export default {
  Calendar,
  CalendarControl,
  CalendarDay,
  CalendarMonth,
}
