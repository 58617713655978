import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const MenuStyles = createMultiStyleConfigHelpers(
  menuAnatomy.keys
).defineMultiStyleConfig({
  variants: {
    'ivt-profile': (props) => ({
      groupTitle: {},
      item: {
        color: 'ivtText',
        py: '.75rem',
        _focus: {
          bg: mode('concord', 'glass.20')(props),
        },
        _active: {
          bg: mode('smokeyGrey', 'glass.30')(props),
        },
      },
      list: {
        boxShadow:
          '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
      },
    }),
  },
})

export default MenuStyles
