// https://v2.chakra-ui.com/docs/styled-system/customize-theme

// https://github.com/chakra-ui/chakra-ui/tree/v2/packages/theme/src
// https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/foundations/typography.ts

import { extendBaseTheme } from '@chakra-ui/react'
import type { ChakraTheme, DeepPartial } from '@chakra-ui/react'
import { CalendarDefaultTheme } from '@uselessdev/datepicker'
// https://v2.chakra-ui.com/community/recipes/using-fonts
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// Component style overrides
import Alert from './components/alert'
import Button from './components/button'
import CloseButton from './components/close-button'
import DatePicker from './components/datepicker'
import Form from './components/form'
import Input from './components/input'
import Menu from './components/menu'
// Main chakra config
import config from './config'
// Foundational style overrides
import colors from './foundations/colors'
import typography from './foundations/typography'
// Global style overrides
import styles from './styles'

const overrides: DeepPartial<ChakraTheme> = {
  config,
  styles,
  ...typography,
  colors,
  // Other foundational style overrides go here
  components: {
    Alert,
    Button,
    CloseButton,
    Input,
    Form,
    Menu,
    Popover: { baseStyle: { popper: { zIndex: 'docked' } } },
    ...DatePicker,
  },
  semanticTokens: {
    colors: {
      ivtBg: { _light: 'white', _dark: 'ivtBlue' },
      ivtButtonBg: { _light: 'midBlue', _dark: 'lightBlue' },
      ivtPanelBg: { _light: 'lightBlue', _dark: 'pitchBlue' },
      ivtText: { _light: 'ivtBlue', _dark: 'white' },
      ivtVideoMutedBg: { _light: 'mutedVideoGrey', _dark: 'mirage' },
    },
  },
}

export default extendBaseTheme(CalendarDefaultTheme, overrides)
