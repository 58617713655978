import type { SystemStyleInterpolation } from '@chakra-ui/react'
import { defineStyleConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

// Following styling patterns outlined at
// https://v2.chakra-ui.com/docs/styled-system/component-style#styling-single-part-components

const sizes: Record<string, SystemStyleInterpolation> = {
  md: {
    h: '2.25rem', // 36px
    minW: '8.5rem', // 136px
    fontSize: 'sm', // 14px
  },
  auto: {
    h: { base: '2.25rem', lg: '2.625rem' },
    minW: { base: '6.875rem', lg: '8.5rem' },
  },
  custom: {},
}

const baseStyle: SystemStyleInterpolation = {
  borderRadius: 'base', // 4px
  textTransform: 'uppercase',
  letterSpacing: '0.01em',
  fontWeight: 'medium',
  lineHeight: '6', // 1.5rem → 24
  fontSize: 'sm', // 14px
  px: 4,
}

const solidDesign: SystemStyleInterpolation = (props) => {
  const disabledStyle = {
    bg: mode('silverChalice', 'lightGrey')(props),
    color: mode('lightWhite', 'grey')(props),
    opacity: 1,
  }

  return {
    ...baseStyle,
    ...(props.size && sizes[props.size]),
    bg: 'ivtButtonBg',
    color: 'ivtBlue',
    _active: {
      bg: 'ivtText',
      color: 'ivtBg',
    },
    _disabled: disabledStyle,
    _hover: {
      bg: 'ivtButtonBg',
      _disabled: disabledStyle,
    },
  }
}

const outlineDesign: SystemStyleInterpolation = (props) => ({
  ...baseStyle,
  ...(props.size && sizes[props.size]),
  borderWidth: '0.125rem',
  borderColor: mode('ivtBlue', 'lightBlue')(props),
  color: 'ivtText',
  _active: {
    bg: mode('concord', 'glass.20')(props),
  },
  _disabled: {
    borderColor: 'lightGrey',
    color: 'lightGrey',
    opacity: 1,
  },
  _hover: {
    bg: 'transparent',
  },
})

const Button = defineStyleConfig({
  variants: {
    'ivt-solid': (props) => solidDesign(props),
    'ivt-outline': (props) => outlineDesign(props),
    'ivt-solid-rounded': (props) => ({
      ...baseStyle,
      ...solidDesign(props),
      borderRadius: 'full',
    }),
    'ivt-outline-rounded': (props) => ({
      ...baseStyle,
      ...outlineDesign(props),
      borderRadius: 'full',
    }),
  },
})

export default Button
