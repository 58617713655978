/**
 * A convenient hook to manage confirm dialogues app-wide,
 * loosely inspired by @see https://hashnode.com/post/material-ui-reusable-confirmation-dialog-in-react-ckr1vr36c0gnxpts126vsaccp
 * and @see https://devrecipes.net/custom-confirm-dialog-with-react-hooks-and-the-context-api/
 */

import create from 'zustand'

import type { ConfirmDialogProps } from 'src/components/ConfirmDialog'

import useI18n from './useI18n'

export type Confirm = (options?: UseConfirmOptions) => Promise<boolean>
export type UseConfirmOptions = Partial<
  Omit<ConfirmDialogProps, 'isOpen' | 'onClose'>
>

export const useConfirmDialogStore = create<ConfirmDialogProps>((set) => ({
  headerText: undefined,
  isOpen: false,
  onClose: () => set({ isOpen: false }),
}))

function useDefaultOptions(): UseConfirmOptions {
  const { t } = useI18n('confirmDialog')
  return {
    headerText: t('headerMessage'),
    cancelButtonText: t('cancel'),
    confirmButtonText: t('confirm'),
  }
}

/**
 * @param initialOptions
 * @return A confirm method that opens a {@link ConfirmDialogProps|ConfirmDialog}, pre-configured with our {@link useDefaultOptions|defaultOptions}, merged with the provided `initialOptions`
 */
export default function useConfirm(
  initialOptions: UseConfirmOptions = {}
): Confirm {
  initialOptions = { ...useDefaultOptions(), ...initialOptions }

  return (options: UseConfirmOptions = {}) =>
    new Promise((resolve) => {
      options = { ...initialOptions, ...options }
      const isOpen = true

      function onConfirm() {
        options.onConfirm?.()
        resolve(true)
      }

      function onCancel() {
        options.onCancel?.()
        resolve(false)
      }

      useConfirmDialogStore.setState({
        ...options,
        isOpen,
        onConfirm,
        onCancel,
      })
    })
}
