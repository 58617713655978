import { useBreakpoint, useBreakpointValue } from '@chakra-ui/react'

/**
 * This file contains simplified `useBreakpoint…`-hooks for client-only use with SSR disabled.
 * This avoid flashes in the layout flow on page load (which may also trigger additional renders)
 * whenever the client breakpoint is non-`base`.
 *
 * This is because the chakra hooks with SSR enabled return the fallback value (defaulting to `base`)
 * at first render, before media query kicks in which then causes another render cycle.
 *
 * See [Chakra-UI documentation](https://v2.chakra-ui.com/docs/hooks/use-breakpoint-value#usage):
 * > This hook is built to work in server-side rendering (SSR) applications by default.
 * > You might notice a quick flash of incorrect media query values when you use them.
 *
 * In case we need an SSR implementation in the future, an improved approach is described [here](https://github.com/chakra-ui/chakra-ui/issues/8200#issuecomment-2225664358)
 */

/**
 * Returns the current breakpoint
 */
function useClientBreakpoint(fallback = 'base'): string {
  return useBreakpoint({ ssr: false, fallback })
}

/**
 * Get the value for the current breakpoint from the provided responsive values object.
 *
 * @example
 * const width = useClientBreakpointValue({ base: '150px', md: '250px' })
 */
function useClientBreakpointValue<T>(
  values: Partial<Record<string, T>> | Array<T>,
  fallback = 'base'
): T | undefined {
  return useBreakpointValue(values, { ssr: false, fallback })
}

/**
 * Convenience-method to determine if client breakpoint is equal to `base`
 */
function useIsClientMobile(): boolean {
  return useClientBreakpoint() === 'base'
}

export { useClientBreakpoint, useClientBreakpointValue, useIsClientMobile }
