import { type DeepPartial, baseTheme } from '@chakra-ui/react'

const typography: DeepPartial<typeof baseTheme> = {
  fonts: {
    heading: `Roboto, ${baseTheme.fonts?.heading}`,
    body: `Roboto, ${baseTheme.fonts?.body}`, // `<installed font>, ${base.fonts?.body}`,
  },
}

export default typography
