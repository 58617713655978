import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import type {
  IconButtonProps,
  IconProps,
  MenuItemProps,
} from '@chakra-ui/react'
import {
  Icon,
  IconButton,
  MenuItem,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

import useI18n from 'src/utils/hooks/useI18n'

interface ColorModeSwitchProp {
  iconProps?: IconProps
}

export const ColorModeSwitch = ({
  iconProps,
  ...props
}:
  | (Partial<MenuItemProps> & ColorModeSwitchProp)
  | (Partial<IconButtonProps> & ColorModeSwitchProp)) => {
  const { t } = useI18n('HostLayout')
  const { toggleColorMode } = useColorMode()
  const SwitchIcon = useColorModeValue(MoonIcon, SunIcon)
  const switchText = useColorModeValue('switchToDarkMode', 'switchToLightMode')

  if (props.role === 'menuitem' || props.as?.['displayName'] === 'MenuItem') {
    return (
      <MenuItem {...props} onClick={toggleColorMode}>
        <Icon as={SwitchIcon} {...iconProps} />
        {t(switchText)}
      </MenuItem>
    )
  }

  return (
    <IconButton
      size="md"
      fontSize="lg"
      aria-label={t(switchText)}
      variant="ghost"
      color="current"
      onClick={toggleColorMode}
      icon={<SwitchIcon />}
      {...props}
    />
  )
}
